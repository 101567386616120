<template>
  <draggable
    ref="ruleDragRef"
    class="drag-ele el-select dp-flex align-item-c flex-w"
    v-model="myArray"
    ghost-class="ghostClass"
    dragClass="bg-move"
    :forceFallback="true"
    @start="handleStart"
    @end="handleEnd"
    v-bind="{ group: 'title', animation: 150 }"
    @change="handleChange"
    draggable=".item"
  >
    <div class="item pr" v-for="item in myArray" :key="item">
      <span class="el-tag el-tag--info el-tag--small el-tag--light"
        ><span class="el-select__tags-text">{{ item }}</span
        ><i class="el-tag__close el-icon-close" @click="handleDel(item)"></i
      ></span>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  props: {
    initList: { type: Array, default: () => [] }
  },
  data() {
    return {
      drag: true,
      myArray: [],
      moveItem: {}
    };
  },
  methods: {
    handleStart(evt) {
      this.drag = true;
      // console.log("handleStart(evt)", evt);
      if (evt.originalEvent.target.className == "el-icon-close pa") {
        this.handleDel(this.myArray[evt.oldIndex]);
      }
    },
    handleEnd(evt) {
      this.drag = false;
    },
    handleChange(evt) {
      // console.log("handleChange(evt)", evt);
      // console.log("1-this.myArray", this.myArray);
      this.$emit("sort-change", this.myArray);
    },
    handleDel(item) {
      // console.log("handleDel(item)", item);
      let index = this.myArray.findIndex(m => m == item);
      this.myArray.splice(index, 1);
      this.$emit("del-item", this.myArray);
    }
  },
  mounted() {
    this.$refs.ruleDragRef.$el.addEventListener("click", (e) => {
      // this.$message({
      //   type: "info",
      //   message: "drag点击了！"
      // });
      e.currentTarget.style.display = "none";
      this.$emit("click-drag", {e});
    });
  },
  watch: {
    initList: {
      handler(val) {
        // console.log("initList", val);
        this.myArray = [...val];
        // console.log("this.myArray", this.myArray);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/commonly";
.drag-ele {
  padding: 1px 0;
  line-height: normal;
  .el-icon-rank {
    margin-right: 15px;
  }
  .bg-move {
    background-color: rgb(241, 247, 255);
  }
  .ghostClass {
    @extend .bg-theme;
    font-size: 0;
  }
}
</style>