<template>
  <el-dialog
    custom-class="cus-param-dialog common-dialog"
    :visible="visible"
    title="自定义参数设置"
    width="600px"
    @close="handleClose"
  >
    <p class="mb-10">网址预览: http://gads2test.gienor.com/?{{ cusTitle }}</p>
    <!-- <el-form
      class="com-form"
      label-width="100px"
      ref="formRef"
      :model="formModel"
      :rules="formRules"
    >
      <el-form-item
        v-for="(item, index) in cusData"
        :key="index"
        :label="index == 0 ? '网站地址后缀' : ''"
      >
        <el-form-item
          :prop="item.ipt + index"
          :rules="{
            validator: (rule, value, callback) =>
              handleValidateEvent(rule, value, callback, {
                prop: item.sel + index,
                mark: item.sel
              }),
            trigger: ['change', 'blur']
          }"
        >
          <el-input
            @change="val => handleChangeEvent(val, { mark: 'ipt', index })"
            clearable
            placeholder="请输入变量名"
            v-model="formModel[item.ipt + index]"
          ></el-input>
        </el-form-item>
        <span class="px-10">=</span>
        <el-form-item
          :prop="item.sel + index"
          :rules="{
            validator: (rule, value, callback) =>
              handleValidateEvent(rule, value, callback, {
                prop: item.ipt + index,
                mark: item.ipt
              }),
            trigger: ['change', 'blur']
          }"
        >
          <el-select
            :disabled="!formModel[item.ipt + index]"
            @change="val => handleChangeEvent(val, { mark: 'sel', index })"
            allow-create
            clearable
            filterable
            v-model="formModel[item.sel + index]"
            placeholder="请选择命名变量或直接输入"
          >
            <el-option
              v-for="(opt, i) in selDatas"
              :key="i"
              :label="opt"
              :value="opt"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-tooltip content="删除">
          <i
            v-if="index > 0"
            @click="handleDelItem(index)"
            class="el-icon-remove-outline color-theme cursor-pointer ml-10"
          ></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item>
        <el-button v-if="cusData.length < 5" type="text" @click="handleAddItem"
          >添加</el-button
        >
      </el-form-item>
    </el-form> -->
    <web-url-config v-if="visible" :initData="initData" :selDatas="selDatas" ref="webConfig" :currentTag="currentTag" @customSetEvent="customSetEvent"></web-url-config>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import webUrlConfig from '@/views/components/webUrlConfig.vue';
export default {
  components: {
    webUrlConfig
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    connectors: {
      type: Array,
      default: () => []
    },
    initData: {
      type: Object,
      default: () => {}
    },
    selDatas: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cusTitle() {
      let str = "";
      if (this.initData.cusStr) {
        if (this.initData.tplStr) {
          str = this.initData.tplStr + "&" + this.initData.cusStr;
        } else {
          str = this.initData.cusStr;
        }
      } else {
        str = this.initData.tplStr;
      }
      return str;
    },
    currentTag(){
        return (this.setFlag?this.customSet:this.initData.cusStr)
    }
  },
  data() {
    return {
      formModel: {},
      formRules: {},
      cusData: [{ ipt: "var", sel: "param" }],
      customSet:'',
      setFlag:false
    };
  },
  methods: {
    handleClose() {
      this.formModel = {};
      this.$emit("close-dialog");
    },
    customSetEvent(v){
      console.log(v);
      this.setFlag = true
      this.customSet = v
    },
    handleConfirm() {
      this.$refs.webConfig.$refs.formRef.validate(v=>{
        console.log(v);
        if(v){
          console.log(this.$refs.webConfig.createCusStrFn);
          console.log('cccc');
          this.$emit("confim-cus-param", {...this.initData,cusStr:this.$refs.webConfig.createCusStrFn});
          this.cusData = []
        }
      })
      
    },
    // 添加
    handleAddItem() {
      this.cusData.push({ ipt: "var", sel: "param" });
    },
    // create-cusStr
    createCusStrFn() {
      let str = "";
      this.cusData.forEach((item, index) => {
        let iptVal = this.formModel[item.ipt + index],
          selVal = this.formModel[item.sel + index];
        if (iptVal) {
          if (selVal) {
            str += "&" + iptVal + "=" + selVal;
          } else {
            str += "&" + iptVal + "=" + "";
          }
        }
      });
      this.$emit("change-event", str.slice(1));
    },
    // 删除
    handleDelItem(index) {
      this.cusData.splice(index, 1);
      delete this.formModel["var" + index];
      delete this.formModel["param" + index];
      this.createCusStrFn();
    },
    // 输入框-选择框-改变
    handleChangeEvent(val, { mark, index }) {
      if (mark == "ipt") {
        this.formModel["param" + index] = "";
      }
      this.createCusStrFn();
    },
    // validate-ipt-select
    
  },
  watch: {
    initData: {
      handler(_val) {
        let arr = [],
          obj = {},
          val = _val.cusStr;
        if (val) {
          let items = val.split("&");
          items.forEach((item, index) => {
            arr.push({ ipt: "var", sel: "param" });
            obj["var" + index] = item.split("=")[0];
            obj["param" + index] = item.split("=")[1];
          });
        } else {
          arr = [{ ipt: "var", sel: "param" }];
          obj["var0"] = "";
          obj["param0"] = "";
        }
        this.cusData = [...arr];
        this.formModel = { ...obj };
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.cus-param-dialog {
  .el-dialog__header {
    @extend .dp-flex;
    @extend .justify-c;
  }
  .el-dialog__title {
    @extend .text-a-c;
  }
  
}
</style>